import React from 'react';
import Footer from '../Components/Footer.js';
import Navbar from '../Components/Navbar.js';
import LoginP from '../Pages/login.js';

const Login = () => {
  return (
    <div className="Login">
       {/* <Navbar /> */}
      <LoginP />
      {/* <Footer /> */}
    </div>
  );
};

export default Login;
