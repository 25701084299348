import P from "../assets/P.jpg";
import AM from "../assets/AM.jpg";
import GV from "../assets/GV.jpg";
import GSV from "../assets/GSV.jpg";
import V from "../assets/V.jpg";

export const ListPeople = [
    {
      url:P,
      name: "Pradeep Boopathy" ,
      title: "Founder",
      location: "Chennai",
      mail: "pradeepboopathy@austinpartnership.in",
    },
    {
      url:AM,
      name: "Arunmuhil Durai" ,
      title: "Co-Founder",
      location: "tirupattur",
      mail: "arunmuhildurai@austinpartnership.in",
    },
    {
      url:V,
      name: "Vinith Kumar" ,
      title: "Co-Founder",
      location: "Bangalore",
      mail: "vinithkumar@austinpartnership.in",
    },
    {
      url:GV,
      name: "Gopinath Vasan" ,
      title: "Co-Founder(Tech-Head)",
      location: "krishnagiri",
      mail: "vasangopinath@austinpartnership.in",
    },
    {
      url:GSV,
      name: "Gowthaman S V" ,
      title: "Tech-developer",
      location: "Vellore",
      mail: "gowthamanvimalnathan@austinpartnership.in",
    },

];    